<template>
  <div
      class="aspect-100/140 md:aspect-100/145 lg:aspect-100/160 xl:aspect-100/147 w-full relative p-4 xl:p-6 pb-3 rounded-16px overflow-hidden flex flex-col border border-bw-10"
  >
    <div class="aspect-92/53 w-full skeleton mb-3 lg:mb-4.5"></div>

    <div class="px-2 flex flex-col h-full">
      <div class="flex justify-between items-center lg:mb-4">
        <div class="w-12 h-12 lg:w-10 lg:h-10 skeleton"></div>

        <div class="w-6 h-6 lg:w-4.5 lg:h-4.5 skeleton"></div>
      </div>

      <div class="flex-1 flex flex-col">
        <div class="h-6 md:mb-2 skeleton w-1/2"></div>
        <!-- <GlobalSkeleton :loading="loading" class="w-full h-7 mb-4" /> -->

        <div class="mt-1 mb-2.5 h-16 skeleton"></div>

        <div class="mb-5 h-18 skeleton"></div>

        <div class="w-full h-11 rounded-23px mt-auto mb-3 lg:mb-2 xl:mb-0 skeleton"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
