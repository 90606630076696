<template>
  <section class="container py-20">
    <div class="w-full">
      <form
          @submit.prevent="submitSearch(true)"
          class="w-full flex gap-6 mb-14 md:flex-row flex-col lg:max-w-211"
      >
        <div
            class="flex-1 flex gap-2 px-4 py-3 items-center bg-white rounded-2.5 w-full lg:w-75 input-search-faq border-bw-08 border"
        >
          <span class="i-ic:twotone-search w-6 h-6 text-primary"></span>
          <input
              type="text"
              :placeholder="t('FIND_THE_RIGHT_QUESTION')"
              class="flex-1 border-none text-bw-16 placeholder-text-bw-16 text-black"
              v-model="search"
          />
        </div>
        <button-primary
            :title="t('SEARCH')"
            class="!normal-case !rounded-2 !font-normal !w-full md:!w-max"
        ></button-primary>
      </form>
      <div class="space-y-3 mb-10">
        <h1 class="text-primary text-2xl font-medium leading-30px">
          <span>{{ t('SEARCH_RESULTS') }}</span>
          <span>“ {{ route.query.word }}”</span>
        </h1>
        <p class="text-gray-500 flex gap-1" v-if="dataRender && dataRender.length > 0">
          <span>{{ t('FIND') }}</span>
          <span>{{ res?.total_result || res?.data?.filter_count }}</span>
          <span> {{ t('SEARCH_RESULTS') }}</span>
        </p>
        <p class="text-gray-500" v-else>
          {{ t('NO_RESULTS_WERE_FOUND') }}
        </p>
      </div>
      <ul
          :class="isProduct ? '' : 'lg:max-w-211'"
          class="flex gap-10 font-medium text-shade-black-06 relative mb-10"
      >
        <li class="h-1px w-full bg-gray-300 absolute bottom-2px left-0 -z-1"></li>
        <li
            class="hover:text-primary hover:border-b-3 pb-2 border-primary duration-300 cursor-pointer"
            :class="checkTypeNews ? '' : 'text-primary border-b-3 pb-2 border-primary'"
            @click="changePageProduct()"
        >
          {{ t('PRODUCT') }}
        </li>
        <li
            class="hover:text-primary hover:border-b-3 pb-2 border-primary duration-300 cursor-pointer"
            :class="checkTypeNews ? 'text-primary border-b-3 pb-2 border-primary' : ''"
            @click="changePageNews()"
        >
          {{ t('NEWS') }}
        </li>
      </ul>
      <!-- <pre>{{ JSON.stringify(dataRender, null, 4) }}</pre> -->
      <!-- news -->
      <div class="flex flex-col gap-8 mb-14 lg:max-w-211" v-if="loading && !isProduct">
        <item-search-loading :loading="loading" v-for="index in 4" :key="index"></item-search-loading>
      </div>
      <div class="flex flex-col gap-8 mb-14 lg:max-w-211" v-if="!isError && !loading && !isProduct">
        <item-search :data="item" v-for="item in dataRender" :key="item?.id"></item-search>
      </div>

      <!-- product -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-14" v-if="loading && isProduct">
        <item-loading-product :loading="loading" v-for="index in 3" :key="index"></item-loading-product>
      </div>
      <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-14"
          v-if="!isError && !loading && isProduct"
      >
        <global-box-card-product
            :item="item"
            v-for="item in dataRender"
            :key="item?.id"
        ></global-box-card-product>
      </div>

      <div v-if="!loading && isError" class="flex justify-center w-full lg:py-35 py-20">
        <img src="/images/global/notFound.svg" alt="notFound" class="w-104 aspect-104/70 object-contain"/>
      </div>

      <div
          :class="isProduct ? '' : 'lg:max-w-211'"
          class="w-full flex justify-center"
          v-if="!isError && !loading"
      >
        <global-pagination
            :current-page="+res?.current_page ? +res?.current_page : +page"
            :total-page="+res?.total_page ? +res?.total_page : +total_page"
        ></global-pagination>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
export default {
  name: 'search'
}
</script>

<script setup lang="ts">
import ItemSearch from './components/ItemSearch.vue'
import ItemSearchLoading from './components/ItemSearchLoading.vue'
import ItemLoadingProduct from './components/ItemLoadingProduct.vue'

import {useGetLangCurrent} from '../../stores/useLanguageMenu'
import {computed, ref} from 'vue'

const {searchNews} = useIZNewsSearch()
const {searchProducts} = useProducts()
const store = useGetLangCurrent()
const {t} = useI18n()
const route = useRoute()

const type = computed(() => route.query?.type)
const res = ref()
const lang_switch = ref([])
const path = ref(useRouter().currentRoute.value.path)
const page = computed(() => route.query?.page || 1)
const total_page = computed(() => Math.ceil(res.value?.data?.filter_count / 9))
const dataRender = ref()
const search = ref(route.query.word)
const loading = ref(false)
const isError = ref(false)
const isProduct = ref(false)

const changePageNews = async () => {
  isProduct.value = false

  await useRouter().push({
    path: path.value,
    query: {
      type: t('URL_NEWS'),
      word: search.value,
      page: 1
    }
  })
  submitSearch(true)
}

const changePageProduct = async () => {
  isProduct.value = true

  await useRouter().push({
    path: path.value,
    query: {
      type: t('URL_PRODUCT'),
      word: search.value,
      page: 1
    }
  })
  submitSearch(true)
}

const checkTypeNews = computed(() => {
  if (type.value == 'tin-tuc' || type.value == 'news') {
    return true
  } else {
    return false
  }
})
// const count = ref(1)

const submitSearch = async (isSearch: Boolean) => {
  loading.value = true
  if (search.value) {
    await useRouter().push({
      path: path.value,
      query: {
        type: type.value,
        word: search.value,
        page: route.query.page && !isSearch ? route.query.page : 1
      }
    })

    if (type.value == 'tin-tuc' || type.value == 'news') {
      isProduct.value = false
      res.value = await searchNews(route.params.langCode, search.value, {
        page: page.value
      }).then((response) => response)
      dataRender.value = res.value?.search_results
      if (dataRender.value.length > 0) {
        isError.value = false
      } else {
        isError.value = true
      }
      lang_switch.value = []
      lang_switch.value = res.value?.lang_switch
      lang_switch.value.map((item) => {
        if (item.lang_code == 'en-US') {
          item.url += '?type=news'
        } else {
          item.url += '?type=tin-tuc'
        }
        item.url += '&word=' + search.value
        item.url += '&page=' + page.value
      })

      store.lang_switch = lang_switch.value
    } else {
      isProduct.value = true

      res.value = await searchProducts(
          {text: search.value, lang: route.params.langCode},
          {limit: 9, page: page.value}
      )

      dataRender.value = res.value?.products

      if (dataRender.value.length > 0) {
        isError.value = false
      } else {
        isError.value = true
      }

      lang_switch.value = res.value?.lang_switch
      lang_switch.value.map((item) => {
        if (item.lang_code == 'en-US') {
          item.url += '?type=product'
        } else {
          item.url += '?type=san-pham'
        }
        item.url += '&word=' + search.value
        item.url += '&page=' + page.value
      })

      store.lang_switch = lang_switch.value
    }
  }
  loading.value = false
}
watch(
    () => page.value,
    () => {
      submitSearch(false)
    }
)

useDirectusCollectionSeo('search_index', {})
onMounted(() => {
  submitSearch(true)
  store.lang_switch = lang_switch.value
})
</script>

<style></style>
