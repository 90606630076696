import {consola} from "consola";
import {normalizeDiacritics} from "normalize-text";
import {queryIZNewsWidgets} from "./news.query";
import {typeQueryOptions} from "../../../shared/base.query";
import {useIZNewsUtils} from "./useIZNewsUtils";
import {useParseData} from "../../../utils/parse.utils";
import {TypeNewsDto} from "./news.type";

export const useIZNewsSearch = () => {

  const globalSettings = inject("globalSettings");
  const {getItems} = useDirectusItems();
  const {generateNewsDto, logger} = useIZNewsUtils(globalSettings);
  const {generateLangSwitch} = useGenerateUrl(globalSettings);
  const {calTotalPage} = useParseData();

  const searchNews = (languageCode: string, text?: string, options?: typeQueryOptions): Array<TypeNewsDto> | any => {
    if (text) {
      options = {
        ...options,
        filter: {
          _and: [
            {
              translations: {
                languages_code: {
                  _eq: languageCode
                }
              }
            },
            {
              translations: {
                _or: [
                  {
                    title: {
                      _contains: normalizeDiacritics(text.toString())
                    }
                  },
                  {
                    intro: {
                      _contains: normalizeDiacritics(text.toString())
                    }
                  }
                ]
              }
            }
          ]
        },
        page: options?.page || 1,
        limit: options?.limit || 10,
        meta: ["filter_count"]
      };
    }
    let query = queryIZNewsWidgets(options);

    return getItems({
      ...query
    }).then(({data, meta}: any) => {
      return {
        search_results: data.map((ite: any) => generateNewsDto(ite, languageCode)),
        total_page: calTotalPage(+meta.filter_count, options?.limit || 10),
        total_result: meta.filter_count,
        current_page: options?.page || 1,
        lang_switch: generateLangSwitch("result", languageCode)
      }
    }).catch(e => {
      logger.error("Error: searchNews!");
      logger.debug(e);
      return {};
    })
  }

  return {
    searchNews,
    logger
  }
}
